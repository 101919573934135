import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <span>Twitter: </span>
    <a href="https://twitter.com/sususues">@sususues</a>
    <br/>
    <span>GitHub: </span>
    <a href="https://github.com/hyotylainensusanna">hyotylainensusanna</a>
  </Layout>
)

export default IndexPage
